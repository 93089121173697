const keys = {
    'open-weather-map': {
        key: 'b2925fbe3a2cee0e56c9301bbb4e0223',
        uri: {
            current: 'https://api.openweathermap.org/data/2.5/weather',
            forecast: 'https://api.openweathermap.org/data/2.5/forecast',
            hourly: 'https://api.openweathermap.org/data/2.5/forecast/hourly',
        }
    },
    'accuweather': {
        key: 'EwJzV8KQsH7H2QyI1xV845DxOcnhi8tE',
        uri: {
            current: 'http://dataservice.accuweather.com/currentconditions/v1/',
            forecast: 'http://dataservice.accuweather.com/forecasts/v1/daily/5day/',
        }
    }
};

export default keys