import React from 'react';
import { UNITS_IMPERIAL } from '../../apis/open-weather-map';

// { timestamp, temperature, humidity, description, icon, wind }

export const Wind = ({wind, units}) => {
    if (!wind || !wind.speed || !wind.direction) return '';
    const { speed, direction, gusts } = wind;
    const SPEED = units === UNITS_IMPERIAL ? 'MPH' : 'm/s';

    return (
        <div className="wind">
            <div className="label">Wind to the {direction.toUpperCase()}</div>
            <div className="speed">{speed} {SPEED}</div>
            {gusts && 
                <div className="gusts">with gusts up to {gusts} {SPEED}</div>
            }
        </div>
    );
}

export const Description = ({description, icon}) => {
    if (!description && !icon) return '';
    
    return (
        <div className="description">
            <div className="label">{description}</div>
            <div className="icon">
                <img alt={description} src={icon} />
            </div>
        </div>
    );
}

export const Humidity = ({humidity}) => {
    if (!humidity) return '';

    return (
        <div className="humidity">
            <div className="label">Humidity: </div>
            <div className="value">{humidity}%</div>
        </div>
    );
}

export const Temperature = ({temperature, units}) => {
    if (!temperature) return '';

    return (
        <div className="temperature">{`${temperature}°${units === UNITS_IMPERIAL ? 'F' : 'C'}`}</div>
    );
}

export const Timestamp = ({timestamp}) => {
    if (!timestamp) return '';
    const d = new Date(timestamp);

    return (
        <div className="date">
            <div className="month-date">{toMonth(d.getMonth())} {d.getDate()}</div>
            <div className="day-time">
                <div className="day">{toDay(d.getDay())}</div>
                <div className="time">{toTime(d.getHours(), d.getMinutes())}</div>
            </div>
        </div>
    );
}


function toDay(i) {
    return [
        'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
    ][i];
}

function toMonth(i) {
    return [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'
    ][i];
}

function toTime(hour, minute) {
    if (minute < 10) {
        minute = '0' + minute;
    }
    if (hour === 0) {
        return `12:${minute} a`;
    } else if (hour > 12) {
        return `${hour - 12}:${minute} p`;
    } else {
        return `${hour}:${minute} a`;
    }
}
