import openWeatherMap from '../apis/open-weather-map';
import * as current from './current';
import * as forecast from './forecast';
import * as hourly from './hourly';
import { CHANGE_UNITS, CHANGE_LOCATION } from './settings';

const applicationMiddleware = ({ getState, dispatch }) => next => action => {
  const { location, units } = getState().settings;

  if (action.type === CHANGE_UNITS || action.type === CHANGE_LOCATION) {
    // should update weather data
    dispatch(current.clearWeather());
    dispatch(hourly.clearWeather());
    dispatch(forecast.clearWeather());
  }

  if (action.type === current.GET_WEATHER_PLEASE) {
    openWeatherMap.current(location.id, units).then(data => {
      dispatch({
        type: current.GET_WEATHER_RESPONSE,
        payload: data,
      });
    });

    return dispatch({ type: current.GET_WEATHER_REQUESTED });
  }

  if (action.type === hourly.GET_WEATHER_PLEASE) {
    openWeatherMap.hourly(location.id, units).then(data => {
      dispatch({
        type: hourly.GET_WEATHER_RESPONSE,
        payload: data,
      });
    })
    return dispatch({ type: hourly.GET_WEATHER_REQUESTED });
  }

  if (action.type === forecast.GET_WEATHER_PLEASE) {
    openWeatherMap.forecast(location.id, units).then(data => {
      dispatch({
        type: forecast.GET_WEATHER_RESPONSE,
        payload: data,
      });
    })
    return dispatch({ type: forecast.GET_WEATHER_REQUESTED });
  }

  return next(action)
}

export default applicationMiddleware
