import openWeatherMaps, { SIOUX_FALLS, UNITS_IMPERIAL, UNITS_METRIC } from '../apis/open-weather-map';

export const ADD_LOCATION = 'settings/ADD_LOCATION'
export const REMOVE_LOCATION = 'settings/REMOVE_LOCATION'
export const CHANGE_LOCATION = 'settings/CHANGE_LOCATION'
export const RETREIVE_LOCATIONS = 'settings/RETREIVE_LOCATIONS'
export const CHANGE_UNITS = 'settings/CHANGE_UNITS'

const BASE_LOCATION = { name: "Sioux Falls", id: SIOUX_FALLS };

const initialState = {
  location: BASE_LOCATION,
  locationOptions: [],
  units: UNITS_IMPERIAL,
}

export default (state = initialState, action) => {
  let newLocation;
  switch (action.type) {
    case REMOVE_LOCATION:
      return {
        ...state,
        locations: state.locations.filter(_ => _.id !== action.payload)
      };
    case CHANGE_LOCATION:
      newLocation = state.locationOptions.filter(_ => _.id === action.payload)
      newLocation = newLocation.length ? newLocation[0] : state.location;
      return {
        ...state,
        location: newLocation
      };
    case RETREIVE_LOCATIONS:
      return {
        ...state,
        locationOptions: action.payload,
      };
    case CHANGE_UNITS:
      const newUnits = state.units === UNITS_IMPERIAL ? UNITS_METRIC : UNITS_IMPERIAL;
      return {
        ...state,
        units: newUnits,
      };

    default:
      return state
  }
}

export const retrieveLocations = () => {
  return dispatch => {
    dispatch({
      type: RETREIVE_LOCATIONS,
      payload: [],
    })

    return openWeatherMaps.locations().then(payload => {
      dispatch({
        type: RETREIVE_LOCATIONS,
        payload,
      })
    })
  }
}

export const changeUnits = () => {
  return dispatch => {
    dispatch({
      type: CHANGE_UNITS
    })
  }
}

export const addLocation = (locationId) => {
  return dispatch => {
    dispatch({
      type: ADD_LOCATION,
      payload: locationId,
    })
  }
}

export const removeLocation = (locationId) => {
  return dispatch => {
    dispatch({
      type: REMOVE_LOCATION,
      payload: locationId,
    })
  }
}

export const changeLocation = (locationId) => {
  return dispatch => {
    dispatch({
      type: CHANGE_LOCATION,
      payload: locationId,
    })
  }
}
