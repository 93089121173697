import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import store, { history } from './store'
import App from './containers/app'

import 'sanitize.css/sanitize.css'
import './index.css'
import { fetchWeather } from './modules/current';
import { fetchForecast } from './modules/forecast'

import openWeatherMap from './apis/open-weather-map'
import { retrieveLocations } from './modules/settings'

const target = document.querySelector('#root')

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <div>
        <App />
      </div>
    </ConnectedRouter>
  </Provider>,
  target
)

// get initial weather data
store.dispatch(fetchWeather());
store.dispatch(fetchForecast());
store.dispatch(retrieveLocations());

openWeatherMap.locations()

// openWeatherMap.current().then(console.log);
// openWeatherMap.forecast().then(console.log);

// accuweather.current().then(console.log);
// accuweather.forecast().then(console.log);
