import React from 'react';

import { Timestamp, Description, Wind, Temperature, Humidity } from './components';

import './info.css';

const Info = ({ weather, location, units }) => {
    const { timestamp, temperature, humidity, description, icon, wind } = weather;
    return (
        <div className="snapshot">
            <div className="header">
                <Timestamp timestamp={timestamp} />
                <Description description={description} icon={icon} />
            </div>
            <div className="data">
                <div className="left">
                    <Temperature temperature={temperature} units={units} />
                </div>
                <div className="right">
                    <Humidity humidity={humidity} />
                    <Wind wind={wind} units={units} />
                </div>
            </div>
        </div>
    );
}

export default Info;
