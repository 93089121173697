import React from 'react'
import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  changeUnits,
  changeLocation,
} from '../../modules/settings'
import { UNITS_IMPERIAL, UNITS_METRIC } from '../../apis/open-weather-map'
import './settings.css';

const titleCase = word => word[0].toUpperCase() + word.substring(1);

const Settings = props => {
  console.log(props.location);
  return (
    <section>
      <h1>Settings (IN DEVELOPMENT)</h1>

      <div>
        <div>
          <label htmlFor="units-select">Units:</label>
        </div>
        <div>
          <select id="units-select" value={props.units} onChange={props.changeUnits}>
            <option value={UNITS_METRIC} key={UNITS_METRIC}>{titleCase(UNITS_METRIC)}</option>
            <option value={UNITS_IMPERIAL} key={UNITS_IMPERIAL}>{titleCase(UNITS_IMPERIAL)}</option>
          </select>
        </div>
      </div>

      <div>
        <div>
          <label htmlFor="location-select">Location:</label>
        </div>
        <div>
          <select id="location-select" value={props.location.id} onChange={e => props.changeLocation(Number(e.target.value))}>
            {props.locationOptions.map(_ => (
              <option value={_.id} key={_.id}>{_.name}</option>
            ))}
          </select>
        </div>
      </div>
    </section>
  )
}
const mapStateToProps = ({ settings }) => ({
  location: settings.location,
  locations: settings.locations,
  locationOptions: settings.locationOptions,
  units: settings.units,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      changeUnits,
      changeLocation,
      changePage: () => push('/about')
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Settings)
