import React, { useEffect } from 'react'
// import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    fetchForecast
} from '../../modules/forecast'
import './forecast.css'
// import LineChart from './linechart'

const Forecast = ({
    fetching,
    weather,
    fetchForecast,
}) => {
    useEffect(() => {
        if (!weather) {
          fetchForecast();
        }
    }, [weather, fetchForecast]);
    

    if (fetching) {
        return 'spinning';
    }

    const highLow = getHighLow(weather);

    return (
        <section className="forecast-weather">
            <div>
                <h1>Forecast</h1>
            </div>

            <div className="lineup">
                {highLow.map(_ => (
                    <div key={`lineup-for-${_.day}`}>
                        <div className="day">{_.day}</div>
                        <div className="high">{_.max}</div>
                        <div className="low">{_.min}</div>
                        {/* <div className="description">{_.description}</div> */}
                        <div className="icon"><img alt={_.description} src={_.icon} /></div>
                    </div>
                ))}
            </div>

            {/* <div>
                <LineChart data={weather} />

            </div> */}

            <p>
                <button onClick={fetchForecast} disabled={fetching}>
                    Get Forecast
                </button>
            </p>
        </section>
    );
}

const mapStateToProps = ({ forecast, settings }) => ({
  weather: forecast.weather,
  fetching: forecast.fetching,
})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
        fetchForecast,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forecast)

function getHighLow(weather) {
    const temps = {};
    
    if (weather) {
        weather.forEach(_ => {
            const d = new Date(_.timestamp);
            const day = toDay(d.getDay());
            if (!temps[day]) {
                temps[day] = [];
            }
            temps[day].push(_);
        })
    }

    return Object.keys(temps)
        .filter(day => temps[day].length > 3)
        .map(day => {
            const icons = {};
            const desc = {};

            let max = -Infinity;
            let min = Infinity;

            temps[day].forEach(_ => {
                max = Math.max(max, _.temperature);
                min = Math.min(min, _.temperature);

                if (!icons[_.icon]) {
                    icons[_.icon] = 0;
                }
                icons[_.icon]++;

                if (!desc[_.description]) {
                    desc[_.description] = 0;
                }
                desc[_.description]++;
            });

            const icon = Object.keys(icons).reduce((acc, cur) => {
                if (!acc) return cur;
                if (icons[cur] > icons[acc]) {
                    return cur;
                }
                return acc;
            }, null);

            const description = Object.keys(desc).reduce((acc, cur) => {
                if (!acc) return cur;
                if (desc[cur] > desc[acc]) {
                    return cur;
                }
                return acc;
            }, null);

            // TODO: add other data collecion here
            //       cloudy/sunny/rainy
            //       an icon



            return { day, max, min, icon, description };
        })
}

function toDay(i) {
    return [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
    ][i];
}
