import React, { useEffect } from 'react'
// import { push } from 'connected-react-router'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
  fetchWeather
} from '../../modules/current'
import './current.css'
import Info from '../info'

export const Current = ({
  weather,
  fetchWeather,
  fetching,
  location,
  units,
}) => {
  useEffect(() => {
    if (!weather) {
      fetchWeather();
    }
  }, [weather, fetchWeather]);

  if (fetching) {
    return 'spinning';
  }

  return (
    <section className="current-weather">
      {weather &&
        <div>
          <h1>Current</h1>

          <Info weather={weather} location={location} units={units} />
        </div>
      }
      <p>
        <button onClick={fetchWeather} disabled={fetching}>
          Get Weather
                </button>
      </p>
    </section>
  );
}

const mapStateToProps = ({ current, settings }) => ({
  weather: current.weather,
  fetching: current.fetching,
  location: settings.location,
  units: settings.units,

})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      fetchWeather,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Current)
