import React from 'react'
import { Route, Link } from 'react-router-dom'
// import Home from '../home'
import About from '../about'
// import Average from '../average'
import Current from '../current'
import Forecast from '../forecast'
import Hourly from '../hourly'
import './app.css';
import Settings from '../settings'
import Location from './location'

const App = () => (
  <div className="app">
    <header>
      <Link to="/current">Current</Link>
      <Link to="/forecast">Forecast</Link>
      <Link to="/hourly">Hourly</Link>
      <Link to="/settings">Settings</Link>
    </header>

    <main>
      <Location />
      <Route exact path="/" component={Current} />
      <Route exact path="/current" component={Current} />
      <Route exact path="/forecast" component={Forecast} />
      <Route exact path="/hourly" component={Hourly} />
      <Route exact path="/settings" component={Settings} />
      <Route exact path="/about" component={About} />      
    </main>
  </div>
)

export default App
