export const GET_WEATHER_REQUESTED = 'counter/GET_FORECASTED_WEATHER_REQUESTED';
export const GET_WEATHER_RESPONSE = 'counter/GET_FORECASTED_WEATHER_RESPONSE';
export const GET_WEATHER_PLEASE = 'counter/GET_FORECASTED_WEATHER';
export const CLEAR_WEATHER = 'forecast/CLEAR_WEATHER';


const initialState = {
  fetching: false,
  weather: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WEATHER_REQUESTED:
      return {
        ...state,
        fetching: true
      }

    case GET_WEATHER_RESPONSE:
      return {
        ...state,
        fetching: false,
        weather: action.payload
      }

    case CLEAR_WEATHER:
      return {
        ...state,
        weather: null
      }


    default:
      return state
  }
}

export const clearWeather = () => ({
  type: CLEAR_WEATHER
})

export const fetchForecast = () => ({
  type: GET_WEATHER_PLEASE
})
