import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import {
    fetchForecast
} from '../../modules/forecast'
import './hourly.css'
import { UNITS_IMPERIAL } from '../../apis/open-weather-map'

const Forecast = ({fetching, weather, fetchForecast, units}) => {
    useEffect(() => {
        if (!weather) {
          fetchForecast();
        }
    }, [weather, fetchForecast]);
    
    if (fetching) {
        return 'spinning';
    }

    const hourly = getHourly(weather);

    return (
        <section className="forecast-weather">
            <div>
                <h1>3-Hourly Forecast</h1>
            </div>

            <div className="hourly">
                {hourly.map((hour, i) => (
                    <div key={`hourly-${i}`} className="hour">
                        <div className="row">
                            <div className="label">{hour.label}</div>
                            <div className="temp">{`${hour.temperature}°${units === UNITS_IMPERIAL ? 'F' : 'C'}`}</div>
                            <div className="icon">
                                <img alt={hour.description} src={hour.icon} />
                            </div>
                        </div>
                    </div>
                ))}
                {/* {highLow.map(_ => (
                    <div key={`lineup-for-${_.day}`}>
                        <div className="day">{_.day}</div>
                        <div className="high">{_.max}</div>
                        <div className="low">{_.min}</div>
                        {/* <div className="description">{_.description}</div> * /}
                        <div className="icon"><img alt={_.description} src={_.icon} /></div>
                    </div>
                ))} */}
            </div>

            {/* <div>
                <LineChart data={props.weather} />

            </div> */}

            <p>
                <button onClick={fetchForecast} disabled={fetching}>
                    Get 3-Hourly Forecast
                </button>
            </p>
        </section>
    );
}

const mapStateToProps = ({ forecast, settings }) => ({
  weather: forecast.weather,
  fetching: forecast.fetching,
  units: settings.units,

})

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
        fetchForecast,
    },
    dispatch
  )

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Forecast)

function getHourly(weather) {
    window.weather = weather
    console.log((weather));
    const today = new Date();

    return (weather || []).map(({
        timestamp,
        humidity,
        temperature,
        wind,
        description,
        icon
    }, i) => {
        const date = new Date(timestamp)

        const label = (i === 0 || date.getHours() === 0)
        ? `${(
                today.getDay() === date.getDay()
                ? 'Today'
                : toDay(date.getDay())
            )} ${to12Hour(date.getHours())}`
        : to12Hour(date.getHours());
        return {
            label, humidity, temperature, wind, description, icon
        }
    });
}

function to12Hour(hour) {
    return `${hour % 12 || 12} ${hour < 12 ? 'a' : 'p'}`;
}

function toDay(i) {
    return [
        'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
    ][i];
}
