import React from 'react'
import { connect } from 'react-redux'

export const Location = ({
  location,
}) => (
  <section className="location-for-data">
    <h2>for {location.name}</h2>
  </section>
);

const mapStateToProps = ({ settings }) => ({
  location: settings.location,
})

export default connect(
  mapStateToProps,
)(Location)
