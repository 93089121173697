import React from 'react'

const About = () => (
  <section>
    <h1>About Page</h1>
    <p>Using a few free weather apis, this should replace the behemoth weather channel app.</p>
  </section>
)

export default About
